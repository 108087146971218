require('../css/app.scss');
require('../fonts/stylesheet.css');
global.$ = global.jQuery = require("jquery");
require('popper.js/dist/popper.js');
require('bootstrap');
require("@fortawesome/fontawesome-free");
require('bootstrap-select');
global.classie = require('desandro-classie');
global.tinymce = require('tinymce');
global.Tablesort = require('tablesort/src/tablesort.js');

import './categoryColor.js';
import './formatNumber.js';

$(document).ready(function() {
    if (window.location.pathname == '/user/') {
        new Tablesort(document.getElementById('sort'));
    }
});

// require('tinymce/themes/silver/theme');
// require('tinymce/skins/ui/oxide-dark/skin.css');
// require('tinymce/skins/ui/oxide-dark/content.css');
// require('tinymce/plugins/image');
// require('tinymce/plugins/link');
// require('tinymce/plugins/lists');
// require('tinymce/plugins/advlist');
// require('tinymce/plugins/fullscreen');
// require('tinymce/plugins/table');
// require('tinymce/plugins/codesample');
// require('tinymce/plugins/help');
// require('tinymce/plugins/searchreplace');
// require('tinymce/plugins/langs/fr_FR');


// tinymce.init({
//     selector: '#article_content',
//     language: 'fr_FR',
//     plugins: 'lists advlist link fullscreen table codesample help searchreplace',
//     toolbar: 'undo redo | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link fullscreen searchreplace',
// });

// tinymce.init({
//     selector: '.tinymce-register',
//     language: 'fr_FR',
//     plugins: 'lists advlist link fullscreen table help searchreplace',
//     toolbar: 'fullscreen undo redo | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | link searchreplace',
// });